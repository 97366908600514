import React from 'react';
import {
  Box,
  Button,
  Typography,
  useTheme,
  lighten,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';

import ExpandableCard from '../../../../../Components/ExpandableCard';

import { MoveBrowserClient } from 'move-sdk';
import LogsReaderDialog from '../../../Partials/ForceUpgrade/ForceUpgradeDialog';
import { Mic, MicOff } from '@mui/icons-material';

export default function StreamingControl(props: {
  device: MoveDevice;
  moveClient: MoveBrowserClient;
  channel: number | undefined;
}) {
  const theme = useTheme();

  const [callId, setCallId] = React.useState('');
  const [onCall, setOnCall] = React.useState(false);
  const [callLoading, setCallLoading] = React.useState(false);
  const [substream, setSubstream] = React.useState(false);
  const [stream, setStream] = React.useState<MediaStream | undefined>();
  const videoRef = React.useRef<HTMLVideoElement>(null);

  React.useEffect(() => {
    if (videoRef && videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);

  const [isMuted, setIsMuted] = React.useState(true);
  const isMutedRef = React.useRef(isMuted);
  React.useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const [testRunning, setTestRunning] = React.useState(false);
  // Use a ref to always have the current test mode value.
  const testRunningRef = React.useRef(testRunning);
  React.useEffect(() => {
    testRunningRef.current = testRunning;
  }, [testRunning]);

  // callDuration now represents the duration after video is received
  const [callDuration, setCallDuration] = React.useState(30);
  const [timeBetweenCalls, setTimeBetweenCalls] = React.useState(10);
  const [testLogs, setTestLogs] = React.useState<{ date: string; timeUntilVideo: number; success: boolean }[]>([]);
  // To record the timestamp when call is placed
  const callStartTimeRef = React.useRef<number | null>(null);
  // Refs to store timeout IDs so they can be canceled if needed.
  const endCallTimeoutRef = React.useRef<number | null>(null);
  const nextCallTimeoutRef = React.useRef<number | null>(null);
  const videoTimeoutRef = React.useRef<number | null>(null);

  const callIdRef = React.useRef<string>('');
  React.useEffect(() => {
    callIdRef.current = callId;
  }, [callId]);


  props.moveClient.onLocalVideo = (stream: MediaStream) => {
    props.moveClient.mute(isMutedRef.current); // Ensure mute state is set for each call
  };

  // This function initiates a call and sets up the callbacks.
  const placeCall = (channel: number | undefined, substream: boolean) => {
    const address = props.device.addresses.find(
      (a) => a.addressType === 'camera' || a.addressType === 'iot_device'
    );
    if (address) {
      setOnCall(true);
      setCallLoading(true);

      // Record the time when the call is placed
      if (testRunningRef.current) {
        callStartTimeRef.current = Date.now();
      }

      // Set up the callbacks common to both live and test modes
      props.moveClient.onCallId = (id: string) => {
        setCallId(id);
        callIdRef.current = id; // Update the ref with the new call ID
      };

      props.moveClient.onVideo = (callId: string, cid: string, event: any) => {
        setCallLoading(false);
        setStream(event);
        // Clear the video timeout if video is received
        if (videoTimeoutRef.current) {
          clearTimeout(videoTimeoutRef.current);
          videoTimeoutRef.current = null;
        }
        // In test mode, measure duration from call start to video start and schedule ending the call
        if (testRunningRef.current && callStartTimeRef.current) {
          const videoTime = Date.now();
          const diffSeconds = (videoTime - callStartTimeRef.current) / 1000;
          setTestLogs((prevLogs) => [
            ...prevLogs,
            { date: new Date().toISOString(), timeUntilVideo: diffSeconds, success: true },
          ]);
          // Clear any previous endCall timeout before setting a new one.
          if (endCallTimeoutRef.current) {
            clearTimeout(endCallTimeoutRef.current);
            endCallTimeoutRef.current = null;
          }
          endCallTimeoutRef.current = window.setTimeout(() => {
            if (testRunningRef.current) {
              endCall();
            }
          }, callDuration * 1000);
        }
      };

      props.moveClient.onHangup = (callId: string) => {
        setOnCall(false);
        setStream(undefined);
        setCallLoading(false);
        // In test mode, schedule the next call after the configured interval.
        if (testRunningRef.current) {
          // Clear any previous next call timeout before setting a new one.
          if (nextCallTimeoutRef.current) {
            clearTimeout(nextCallTimeoutRef.current);
            nextCallTimeoutRef.current = null;
          }
          nextCallTimeoutRef.current = window.setTimeout(() => {
            if (testRunningRef.current) {
              placeCall(props.channel, substream);
            }
          }, timeBetweenCalls * 1000);
        }
      };

      props.moveClient.placeCall(
        address?.address,
        'TN',
        false,
        channel,
        substream,
        (data: any) => {}
      );

      // Set a timeout to mark the call as a failure if no video is received within 30 seconds
      videoTimeoutRef.current = window.setTimeout(() => {
        if (testRunningRef.current && callStartTimeRef.current) {
          const videoTime = Date.now();
          const diffSeconds = (videoTime - callStartTimeRef.current) / 1000;
          setTestLogs((prevLogs) => [
            ...prevLogs,
            { date: new Date().toISOString(), timeUntilVideo: diffSeconds, success: false },
          ]);
          endCall();
        }
      }, 30000); // 30 seconds
    }
  };

  const endCall = () => {
    props.moveClient.hangupCall(callIdRef.current, () => {});
    // onHangup callback will update state and, if testing, schedule the next call.
  };

  const toggleMicrophone = () => {
    const newMuteState = !isMuted;
    props.moveClient.mute(newMuteState);
    setIsMuted(newMuteState);
    isMutedRef.current = newMuteState; // Update the ref with the new mute state
  };

  const [isFullIce, setIsFullIce] = React.useState(props.moveClient.turnOnly);
  const toggleFullIce = () => {
    props.moveClient.turnOnly = !isFullIce;
    setIsFullIce(!isFullIce);
  };

  const [logsOpen, setLogsOpen] = React.useState(false);

  // When starting test mode, clear any pending timeouts and initiate the first call.
  const startTest = () => {
    // Clear any pending timeouts.
    if (endCallTimeoutRef.current) {
      clearTimeout(endCallTimeoutRef.current);
      endCallTimeoutRef.current = null;
    }
    if (nextCallTimeoutRef.current) {
      clearTimeout(nextCallTimeoutRef.current);
      nextCallTimeoutRef.current = null;
    }
    setTestRunning(true);
    testRunningRef.current = true; // Ensure the ref is updated
    placeCall(props.channel, substream);
  };

  // Stop test mode and clear any pending timeouts.
  const stopTest = () => {
    setTestRunning(false);
    testRunningRef.current = false; // Ensure the ref is updated
    if (endCallTimeoutRef.current) {
      clearTimeout(endCallTimeoutRef.current);
      endCallTimeoutRef.current = null;
    }
    if (nextCallTimeoutRef.current) {
      clearTimeout(nextCallTimeoutRef.current);
      nextCallTimeoutRef.current = null;
    }
    endCall();
  };

  const clearTestLogs = () => {
    setTestLogs([]);
  };

  const getFailureCount = () => {
    return testLogs.filter(log => !log.success).length;
  };

  const getAverageTime = () => {
    if (testLogs.length === 0) return 0;
    const total = testLogs.reduce((sum, log) => sum + log.timeUntilVideo, 0);
    return (total / testLogs.length).toFixed(2);
  };

  const getLongestTime = () => {
    if (testLogs.length === 0) return 0;
    return Math.max(...testLogs.map(log => log.timeUntilVideo)).toFixed(2);
  };

  return (
    <>
      <ExpandableCard title="Live Streaming" initiallyOpen smallMargin>
        <Box display="flex" flexDirection="column" rowGap={2}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="space-between"
          >
            <Button
              size="small"
              variant="contained"
              onClick={() =>
                onCall ? endCall() : placeCall(props.channel, substream)
              }
            >
              {onCall ? 'End' : 'Live Video'}
            </Button>
            <Box display="flex" flexDirection="row" columnGap={1}>
              <Button
                size="small"
                variant={substream ? 'contained' : 'outlined'}
                color="secondary"
                onClick={() => setSubstream(!substream)}
              >
                {substream ? 'Turn off Substream' : 'Turn on Substream'}
              </Button>
              <Button
                size="small"
                variant={isMuted ? 'contained' : 'outlined'}
                color="secondary"
                onClick={() => toggleMicrophone()}
              >
                {isMuted ? <MicOff color="error" /> : <Mic />}
                {isMuted ? 'Unmute Microphone' : 'Mute Microphone'}
              </Button>
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                onClick={() => toggleFullIce()}
              >
                {isFullIce ? 'Turn Only' : 'Full Ice'}
              </Button>
              {/* <Button
                size="small"
                variant="outlined"
                color="secondary"
                onClick={() => alert('todo')}
              >
                Full Screen
              </Button> */}
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                onClick={() =>
                  window.open(
                    'chrome://webrtc-internals',
                    '_blank',
                    'nodeIntegration=no'
                  )
                }
              >
                WebRTC
              </Button>
            </Box>
          </Box>
          <Box display="flex" flexDirection="row">
            {!onCall && (
              <Box width={1} display="flex" justifyContent="center" p={2}>
                <Box
                  width={1}
                  sx={{
                    p: 6,
                    minHeight: '320px',
                    borderRadius: '3px',
                    border: `1px solid ${lighten(theme.palette.secondary.main, 0.6)}`,
                    background: lighten(theme.palette.secondary.light, 0.8),
                  }}
                >
                  <Typography
                    component={Box}
                    justifyContent="center"
                    alignItems="center"
                    display="flex"
                    variant="caption"
                    onClick={() => {
                      setLogsOpen(true);
                    }}
                  >
                    Device Thumbnail
                  </Typography>
                </Box>
              </Box>
            )}
            {onCall && callLoading && (
              <Box width={1} p={6}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  rowGap={2}
                >
                  <CircularProgress />
                  <Typography variant="caption">
                    Connecting to Device
                  </Typography>
                </Box>
              </Box>
            )}
            {onCall && !callLoading && stream && videoRef && (
              <Box display="flex" width={1} sx={{ minHeight: '5rem' }}>
                <video
                  ref={videoRef}
                  autoPlay
                  width="100%"
                  onPlaying={() => {
                    console.log('video playing');
                  }}
                ></video>
              </Box>
            )}
          </Box>
        </Box>
      </ExpandableCard>
      <ExpandableCard title="Live Stream Tester" initiallyOpen={false} smallMargin>
        <Box display="flex" flexDirection="column" rowGap={2}>
          <Box display="flex" flexDirection="row" columnGap={2} alignItems="center" justifyContent="space-between">
            <FormControl sx={{ flex: 1 }}>
              <Typography variant="caption">Call Duration (s)</Typography>
              <Select
                value={callDuration}
                onChange={(e) => setCallDuration(Number(e.target.value))}
              >
                {[30, 60, 90, 120, 5, 1].map((duration) => (
                  <MenuItem key={duration} value={duration}>
                    {duration}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ flex: 1 }}>
              <Typography variant="caption">Time Between Calls (s)</Typography>
              <Select
                value={timeBetweenCalls}
                onChange={(e) => setTimeBetweenCalls(Number(e.target.value))}
              >
                {[10, 20, 30, 60, 5, 1].map((time) => (
                  <MenuItem key={time} value={time}>
                    {time}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              size="small"
              variant="contained"
              sx={{ flex: 1, height: '56px', alignSelf: 'flex-end' }}
              onClick={() => (testRunning ? stopTest() : startTest())}
            >
              {testRunning ? 'Stop' : 'Start'}
            </Button>
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              sx={{ flex: 1, height: '56px', alignSelf: 'flex-end' }}
              onClick={clearTestLogs}
            >
              Clear
            </Button>
          </Box>
          <Box display="flex" flexDirection="row" columnGap={2} alignItems="center">
            <Typography variant="body2">Runs: {testLogs.length}</Typography>
            <Typography variant="body2">Failures: {getFailureCount()}</Typography>
            <Typography variant="body2">Average Time: {getAverageTime()} s</Typography>
            <Typography variant="body2">Longest Time: {getLongestTime()} s</Typography>
          </Box>
          <TableContainer component={Paper} sx={{ maxHeight: 300 }}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Date/Time</TableCell>
                  <TableCell>Time Until Video (s)</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {testLogs.slice(0).reverse().map((log, index) => (
                  <TableRow key={index}>
                    <TableCell>{log.date}</TableCell>
                    <TableCell>{log.timeUntilVideo}</TableCell>
                    <TableCell>{log.success ? 'Success' : 'Failure'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </ExpandableCard>
      <LogsReaderDialog
        open={logsOpen}
        onClose={() => {
          setLogsOpen(false);
        }}
        moveClient={props.moveClient}
        deviceId={props.device.addresses[0].address}
        isCamera
      />
    </>
  );
}
